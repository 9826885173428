import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBAE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBAE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBAE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBAE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBAE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBAE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBAE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp
